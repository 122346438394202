import styled from "@emotion/styled"

export const FormCheckbox = styled.label`
  display: block;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;

  input {
    margin-right: 0.5rem;
  }
`
