import styled from "@emotion/styled"
import axios from "axios"
import { graphql } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { BlocksRenderer } from "../components/BlocksRenderer"
import { Button } from "../components/Button"
import { FormCheckbox } from "../components/FormCheckbox"
import { FormInput } from "../components/FormInput"
import { GridForm } from "../components/GridForm"
import { Heading } from "../components/Heading"
import { HeadingBlock } from "../components/HeadingBlock"
import { Hero } from "../components/Hero"
import { Layout } from "../components/Layout"
import { PackageCard } from "../components/PackageCard"
import { Popup } from "../components/Popup"
import { Row } from "../components/Row"
import { SEO } from "../components/SEO"
import { Text } from "../components/Text"
import { TipsSection } from "../components/TipsSection"
import { VisuallyHidden } from "../components/VisuallyHidden"
import AlertIcon from "../images/icons/alert.svg"
import popupImage from "../images/popup-image.jpg"
import "../lib/kirby.interface"
import Theme from "../lib/Theme"

const Grid = styled(Row)`
  position: relative;
  z-index: 3;
  margin-top: 3rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  ${Theme.Mediaquery.medium.min} {
    grid-template-columns: 2fr 4fr;
  }
`

const Card = styled(PackageCard)`
  margin-top: -10rem;
  margin-bottom: 2rem;

  ${Theme.Mediaquery.medium.min} {
    margin-top: -20rem;
  }
`

const Notification = styled.div`
  position: relative;
  border-radius: 5px;
  border: 2px solid ${Theme.Colors.purple.s100};
  padding: 1rem 1rem 1rem 4rem;
  margin: 2rem 0;
  color: ${Theme.Colors.purple.s100};

  svg {
    position: absolute;
    top: 1rem;
    left: 1rem;
    fill: ${Theme.Colors.purple.s100};
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  > div p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

interface DetailPackage extends Kirby.SEO {
  id: string
  content: string
  excerpt: string
  title: string
  mainImage: string
  notification: string
  minimalParticipants: number
  facilities: string[]
}

const PackagePage = ({
  data,
}: Kirby.Data<{ detailPackage: DetailPackage }>) => {
  const content = data.kirby.detailPackage
  const [showConfirmation, setConfirmation] = useState(false)
  const [busy, setBusy] = useState(false)
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = async (form) => {
    setBusy(true)

    if (form.botField) {
      setConfirmation(true)
      reset()
      return
    }

    const res = await axios.post("/.netlify/functions/contact", {
      form,
      type: "package",
      name: content.title,
    })

    if (res.status === 200) {
      setConfirmation(true)
      reset()
    }

    setBusy(false)
  }

  return (
    <Layout>
      <SEO
        title={content.seoTitle || content.title}
        description={content.seoDescription || content.excerpt}
        imageUrl={content.mainImage.url}
      />

      <Hero url={content.mainImage.url} alt={content.mainImage.alt}></Hero>

      <Grid>
        <aside>
          <Card id={content.id} {...data} expanded />
        </aside>
        <article>
          <BlocksRenderer content={content.content} />

          {content.notification && (
            <Notification role="note">
              <AlertIcon />
              <div dangerouslySetInnerHTML={{ __html: content.notification }} />
            </Notification>
          )}

          <HeadingBlock title="Informatie aanvragen" subtitle="Overtuigd?">
            <p>
              Vul onderstaand formulier in om informatie aan te vragen voor de{" "}
              <strong>{content.title} training!</strong>
            </p>
          </HeadingBlock>

          <GridForm name={content.id} onSubmit={handleSubmit(onSubmit)}>
            <VisuallyHidden>
              <label>
                Vul dit veld niet in als je een mens bent{" "}
                <input {...register("botField")} />
              </label>
            </VisuallyHidden>

            <FormInput
              label="Naam"
              htmlFor="naam"
              data-column="medium"
              required
            >
              <input
                type="text"
                id="naam"
                {...register("naam", { required: true })}
              />
            </FormInput>

            <span data-column="medium" />

            <FormInput
              label="E-mailadres"
              htmlFor="email"
              data-column="medium"
              required
            >
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
              />
            </FormInput>

            <FormInput
              label="Telefoonnummer"
              htmlFor="telefoon"
              data-column="medium"
              required
            >
              <input
                type="text"
                id="telefoon"
                {...register("telefoon", { required: true })}
              />
            </FormInput>

            <span data-column="medium" />

            <FormInput
              label="Adres stal (straat en huisnummer)"
              htmlFor="adres"
              required
            >
              <input
                type="text"
                id="adres"
                {...register("adres", { required: true })}
              />
            </FormInput>

            <FormInput label="Postcode" htmlFor="postcode" data-column="small">
              <input type="text" id="postcode" {...register("postcode")} />
            </FormInput>

            <FormInput label="Stad" htmlFor="stad" data-column="large" required>
              <input
                type="text"
                id="stad"
                {...register("stad", { required: true })}
              />
            </FormInput>

            <FormInput
              label="Voorkeursdata &amp; opmerkingen"
              help="Je kan meerdere data benoemen, een bepaalde periode of bepaalde weekdagen."
              htmlFor="beschrijving"
              required
            >
              <textarea
                id="beschrijving"
                {...register("beschrijving", { required: true })}
              />
            </FormInput>

            <FormInput
              label="Verwacht aantal combinaties"
              help="Je kunt het aantal combinaties later altijd nog aanpassen."
              htmlFor="combinaties"
              data-column="medium"
              required
            >
              <input
                type="number"
                min={content.minimalParticipants || 1}
                step={1}
                id="combinaties"
                {...register("combinaties", { required: true })}
              />
            </FormInput>

            {content.id === "pakketten/deluxe" && (
              <FormInput label="Meehelp-korting" htmlFor="meehelpkorting">
                <FormCheckbox>
                  <input
                    type="checkbox"
                    id="meehelpkorting"
                    name="meehelpkorting[]"
                    value="Ja"
                    {...register("meehelpkorting[]")}
                  />
                  Wij helpen mee met op- en afbouwen en krijgen hiervoor €30
                  korting op de totaalprijs
                </FormCheckbox>
              </FormInput>
            )}

            <FormInput label="Bij onze stal is een:" htmlFor="bak">
              <FormCheckbox>
                <input
                  type="checkbox"
                  value="Binnenbak"
                  {...register("bak[]")}
                />
                Binnenbak
              </FormCheckbox>
              <FormCheckbox>
                <input
                  type="checkbox"
                  value="Buitenbak"
                  {...register("bak[]")}
                />
                Buitenbak
              </FormCheckbox>
            </FormInput>

            <Button
              large
              type="submit"
              data-track-content
              data-content-name="FormSubmit"
              data-content-piece={content.title}
              disabled={busy}
            >
              Aanvraag versturen
            </Button>

            <Text as="small" data-column="large" size={14}>
              Velden met een * zijn verplicht
            </Text>
          </GridForm>
        </article>
      </Grid>

      <TipsSection />

      {showConfirmation && (
        <Popup imageUrl={popupImage} onClose={() => setConfirmation(false)}>
          <Heading as="h3">Formulier verstuurd!</Heading>
          <p>
            Bedankt voor je aanvraag! Ik doe mijn best om binnen 2 werkdagen te
            antwoorden.
            {/* Bedankt voor je bericht. Op dit moment heb ik vakantie. Vanaf 28
            februari kan ik weer op je mail reageren. */}
          </p>

          <p>
            Groetjes,
            <br />
            Ilse
            <br />
            <br />
            van Schriktraining Deluxe
          </p>
        </Popup>
      )}
    </Layout>
  )
}

export default PackagePage

export const query = graphql`
  query PackageDetail($id: ID!) {
    kirby {
      detailPackage(id: $id) {
        id
        content
        excerpt
        title
        mainImage {
          url
          alt
        }
        notification
        minimalParticipants
        facilities
        seoDescription
        seoTitle
      }
    }

    allFile(filter: { name: { regex: "/pakketten/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 962, height: 542, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
